import React, { useState } from 'react';

const Style1 = (props) => {

  const [show, setShow] = useState('upload')

  const reset = () => {
    props.onChange()
  }

  /*const changeShow = () =>{
    setShow('style')
  }*/

  return (

    <div>
      <h2>High Contrast</h2>

      <button className="resetButton" onClick ={reset}>Reset </button>

      {show==='upload' ?

      <>
      <input
        style={{display: 'none'}}
        type='file'
        name='image'
        onChange={props.fileHandler}
        ref={props.inputEl}
      />
      <button className="pickFileButton" onClick={props.onInputRefClick}>PICK FILE</button>
      <br /><br />

      {props.invalidImage && <p className="error">{props.invalidImage}</p>}

      {props.selectedPic ?
        <>
        <button className="uploadButton" onClick ={(e)=>{props.uploadHandler(props.styleType); setTimeout((e)=>{setShow('style')},5000)}}>UPLOAD </button><br /> <br />
        </>
        : ''
      }

      {props.handleResponse && <p className={props.handleResponse.isSuccess ? "success" : "error"}>{props.handleResponse.message}</p>}
      <img src = {props.selectedPic} alt={props.selectedImage} style={{height: 300}} />
      </>

        :
        <>
        <button className="portraitButton" onClick ={props.getPortrait}> SEE PORTRAIT </button> <br /><br />

        {props.portraitFind ?
          <>
          <img src = {props.portraitFind} alt={props.styledPortait} style={{height: 300}} /> <br />
          <br />
          <button className="downloadButton" onClick={e => props.download(e)}>

            DOWNLOAD

          </button>

          </>
          : 'loading...press button when ready'
        }

        </>

      }

    </div>

  )
}



export default Style1
