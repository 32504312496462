import React, { useState, useEffect, useRef } from 'react';
import Axios from 'axios'
import Style1 from './components/Style1';
import Style2 from './components/Style2';
import Style3 from './components/Style3';
import loadingImage from './images/squaresWink.gif'
import logoImage from './images/PoP2.png'
import highContrast from './images/contrastMarilynC.jpg'
import antiqueVintage from './images/antiqueMarilynC.jpg'
import pixelGraphic from './images/pixelMarilynC.jpg'
import originalPic from './images/originalMarilynC.jpg'

function App() {

  const [choice, setChoice] = useState('choose')
  const [invalidImage, setinvalidImage] = useState(null)
  const [handleResponse, setHandleResponse] =useState(null)
  //const [loading, setLoading] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const [selectedPic, setSelectedPic] = useState(null)
  const [portrait, setPortrait] = useState('')
  const [portraitFind, setPortraitFind] = useState('')

  useEffect(()=>{
    const data = localStorage.getItem('portraitFind')
    if(data){
      setPortraitFind(JSON.parse(data))
    }
  }, [])

  useEffect(()=>{
    localStorage.setItem('portraitFind', JSON.stringify(portraitFind))
  })

  const inputEl = useRef(null);
  const onInputRefClick = () => {
    // `current` points to the mounted text input element
    inputEl.current.click();
  };

  const reset = () =>{
    setChoice('choose')
    setSelectedPic(null)
    setSelectedFile(null)
    setPortrait('')
    setPortraitFind('')
    setHandleResponse(null)
    setinvalidImage(null)
  }

  const chooseStyle = (choice) => {
    setChoice(choice)
  }

  const fileHandler = (e) =>{
      const imageFile = e.target.files[0];

     if (!imageFile) {
       setinvalidImage('Please select image.')
       setSelectedPic(null)
       return false;
     }

     if (!imageFile.name.match(/\.(jpg|JPG|jpeg|JPEG|png|PNG|)$/)) {
       setinvalidImage('Please select a jpeg, jpg, or png.')
       setSelectedPic(null)
       return false;
     }

     if (imageFile.size > 1024 * 1024 *4){ //4MB max
       setinvalidImage('File too large: 4mb max')
       setSelectedPic(null)
       return false;
     }

     setHandleResponse(null)
     const reader = new FileReader();
      reader.onload = () =>{
        if(reader.readyState === 2){
          setinvalidImage(null)
          setSelectedPic(reader.result)
        }
      }
      reader.readAsDataURL(e.target.files[0])
      setSelectedFile(imageFile)

  }

    const uploadHandler = (styleType) => {
        if (!selectedFile) {
          setHandleResponse({
            isSuccess: false,
            message: "Please select image to upload."})
          return false;
        }
        setHandleResponse(null)
        const name = Date.now() + selectedFile.name
        setPortrait(styleType + name)
        const fd = new FormData()
        fd.append('image', selectedFile, name)
        //Axios.post(`http://localhost:8001/${styleType}`, fd)
        Axios.post(`https://api.portraitsofportraits.com/${styleType}`, fd)
          .then(res => {
            setHandleResponse({
            isSuccess: res.status === 200,
            message: res.data})
            //console.log('hello')
            setSelectedPic(loadingImage)
          })
          .catch(function (error) {
            console.log(error.toJSON())
          })
      }



  const download = e => {
      //fetch(`http://localhost:8001/download/${portrait}`, {
      fetch(`https://api.portraitsofportraits.com/download/${portrait}`, {
        method: "GET",
        headers:{}
    })
      .then(response => {
        response.arrayBuffer().then(function(buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "image.png"); //or any other extension
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        });
      })
      /**clears folders**/
      //.then(Axios.post('http://localhost:8001/clear'))
      .then(Axios.post('https://api.portraitsofportraits.com/clear'))
      /**/
      .catch(err => {
        console.log(err);
      });
  };

  const getPortrait = () =>{
    //setPortraitFind('http://localhost:8001/uploadEdits/'+portrait)
    setPortraitFind('https://api.portraitsofportraits.com/uploadEdits/'+portrait)
  }

  const highContrastAlt = "Marilyn Monroe in a High Contrast Black & White Style Image"
  const antiqueVintageAlt= "Marilyn Monroe in Antique Vintage Style Image"
  const pixelGraphicAlt= "Marilyn Monroe in Pixel Graphic Style Image"
  const popLogoAlt= "Portraits of Portraits Logo"
  const topDirect="top of page"
  const topDirectA="#popLogo"

  const selectedImage ='Your Selected Image is Loading'
  const styledPortait ='Your Styled Portrait'


  return (

    <div>

      <div className="headerWrapper">
        <div className="headerLogo" onClick={()=>{reset()}}>
          <img id ="popLogo" src ={logoImage} alt={popLogoAlt} />
        </div>
        <div className="headerPic">
          {choice === 'style1' ?
            <img src ={highContrast} alt={highContrastAlt} />:
          choice === 'style2' ?
            <img src ={antiqueVintage} alt={highContrastAlt} />:
          choice === 'style3' ?
            <img src ={pixelGraphic} alt={highContrastAlt} />:

            <img src ={originalPic} alt="Famous Marilyn Monroe Pic" />
          }
        </div>
        <div className="headerBanner">
          <h1>Welcome to Portraits of Portraits </h1>
        </div>
      </div>

      {
        choice === 'style1' ?
          <Style1
            inputEl={inputEl}
            onInputRefClick={onInputRefClick}
            styleType={'style1'}
            uploadHandler={uploadHandler}
            value={choice}
            onChange={reset}
            handleResponse={handleResponse}
            invalidImage={invalidImage}
            fileHandler={fileHandler}
            selectedPic={selectedPic}
            getPortrait={getPortrait}
            portraitFind={portraitFind}
            download={download}
          />
        :
        choice === 'style2' ?
          <Style2
            inputEl={inputEl}
            onInputRefClick={onInputRefClick}
            styleType={'style2'}
            uploadHandler={uploadHandler}
            value={choice}
            onChange={reset}
            handleResponse={handleResponse}
            invalidImage={invalidImage}
            fileHandler={fileHandler}
            selectedPic={selectedPic}
            getPortrait={getPortrait}
            portraitFind={portraitFind}
            download={download}
          />
        :
        choice === 'style3' ?
          <Style3
            inputEl={inputEl}
            onInputRefClick={onInputRefClick}
            styleType={'style3'}
            uploadHandler={uploadHandler}
            value={choice}
            onChange={reset}
            handleResponse={handleResponse}
            invalidImage={invalidImage}
            fileHandler={fileHandler}
            selectedPic={selectedPic}
            getPortrait={getPortrait}
            portraitFind={portraitFind}
            download={download}
          />
        :
        <a href ='#Choose' name="choose style">
        <div className='textHolder'>
          <h1 className='text'>CHOOSE YOUR STYLE</h1>
        </div>
        </a>
      }

      {
        choice === 'choose' ?


        <div id="Choose" className="chooseWrapper">
          <a href ={topDirectA} name={topDirect}>
          <div className="choose1" onClick ={(e)=>{chooseStyle('style1')}}>
            <h2>High Contrast</h2>
            <img src ={highContrast} alt={highContrastAlt} />
          </div>
          </a>
          <a href ={topDirectA} name={topDirect}>
          <div className="choose2" onClick ={(e)=>{chooseStyle('style2')}}>
            <h2>Antique Vintage</h2>
            <img src ={antiqueVintage} alt={antiqueVintageAlt}  />
          </div>
          </a>
          <a href ={topDirectA} name={topDirect}>
          <div className="choose3" onClick ={(e)=>{chooseStyle('style3')}}>
            <h2>Pixel Graphic</h2>
            <img src ={pixelGraphic} alt={pixelGraphicAlt} />
          </div>
          </a>
        </div>

        : ''
      }

      <div className="footer">
        <a href ={topDirectA} name={topDirect}>
        <li><img src ={logoImage} alt={popLogoAlt} /></li>
        </a>
        <div id="copy">

          <li>Copyright &copy; 2022</li>
          <li> Portraits of Portraits</li>
          <li> All Rights Reserved</li>

        </div>
      </div>

    </div>

  )


}

export default App;
